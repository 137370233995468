import * as React from "react";
import { Link } from "gatsby";
import Layout from "src/design-system/layouts/layout";
import { withAuthenticator } from "@aws-amplify/ui-react";

// data
const links = [
  {
    text: "Review Surveys",
    url: "/circulytics/review",
    description: "Review surveys that are awaiting an analyst's input",
    color: "#E95800",
  },
  {
    text: "Trigger benchmarks",
    url: "/circulytics/benchmarks",
    description:
      "View the surveys that are pending benchmarks, and create new benchmarks",
    color: "#E95800",
  },
  {
    text: "Generate scorecards",
    url: "/circulytics/scorecards",
    description: "View all surveys and generate scorecards",
    color: "#E95800",
  },
];

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <title>Circulytics | DataSphere</title>
        <h1>
          Welcome to...
          <br />
          <span role="img" aria-label="Purple circle">
            🟣
          </span>{" "}
          Circulytics{" "}
          <span role="img" aria-label="Purple circle">
            🟣
          </span>
        </h1>
        <p>
          This is the user interface for all things Circulytics
          <span role="img" aria-label="Sunglasses smiley emoji">
            😎
          </span>
        </p>
        <br />
        <ul>
          {links.map((link) => (
            <li key={link.text}>
              <span>
                <Link to={link.url}>{link.text}</Link>
                <p>{link.description}</p>
              </span>
            </li>
          ))}
        </ul>
      </main>
    </Layout>
  );
};

export default withAuthenticator(IndexPage);
